var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "center" } }, [
    _c(
      "div",
      { staticClass: "square" },
      [
        _vm._l(_vm.allData.row1, function (item) {
          return _c(
            "div",
            {
              key: item.name,
              staticClass: "bg-color-black item",
              style: {
                height: _vm.kHOne + "px",
                "margin-top": Math.round(_vm.kFOne / 4) + "px",
              },
            },
            [
              _c("p", {
                staticClass: "colorDarkturquoise font-bold",
                style: {
                  "font-size": _vm.kFOne + "px",
                  "text-align": "left",
                  "margin-left": _vm.kFOne + "px",
                },
                domProps: { textContent: _vm._s(item.name) },
              }),
              _c("p", {
                style: { height: Math.round(_vm.kFOne / 1.5) + "px" },
              }),
              _c("p", {
                style: {
                  height: _vm.kHOne + "px",
                  "font-size": Math.round(_vm.kFOne * 1.8) + "px",
                  "text-align": "left",
                  "margin-left": _vm.kFOne + "px",
                  "margin-top": Math.round(_vm.kFOne / 2) + "px",
                  color: _vm.colorText1,
                },
                domProps: { textContent: _vm._s(item.value) },
              }),
            ]
          )
        }),
        _vm._l(_vm.allData.row2, function (item) {
          return _c(
            "div",
            {
              key: item.name,
              staticClass: "bg-color-black item",
              style: {
                height: _vm.kHOne + "px",
                "margin-top": Math.round(_vm.kFOne / 2) + "px",
                "margin-bottom": Math.round(_vm.kFOne / 2) + "px",
              },
            },
            [
              _c("p", {
                staticClass: "colorLightsalmon font-bold",
                style: {
                  "font-size": _vm.kFOne + "px",
                  "text-align": "left",
                  "margin-left": _vm.kFOne + "px",
                },
                domProps: { textContent: _vm._s(item.name) },
              }),
              _c("p", {
                style: { height: Math.round(_vm.kFOne / 1.5) + "px" },
              }),
              _c("p", {
                style: {
                  height: _vm.kHOne + "px",
                  "font-size": Math.round(_vm.kFOne * 1.8) + "px",
                  "text-align": "left",
                  "margin-left": _vm.kFOne + "px",
                  "margin-top": Math.round(_vm.kFOne / 2) + "px",
                  color: _vm.colorText2,
                },
                domProps: { textContent: _vm._s(item.value) },
              }),
            ]
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }