<template>
  <div class="avue-contail" :class="{ 'avue--collapse': keyCollapse }">
    <div class="avue-header" ref="avueHeader" v-if="isShow">
      <!-- 顶部导航栏 -->
      <top ref="top"/>
    </div>
    <div class="avue-layout" :style="{paddingLeft:paddingLeft}">
      <!-- 左侧导航栏  -->
     <div class="avue-left" v-if="isShow">
        <sidebar/>
        <hamburger :is-active="isCollapse" class="hamburger-container" @toggleClick="showCollapse" />
      </div>
      <div class="avue-main">
        <!-- 顶部标签卡 -->
        <tags v-if="isShow"/>
        <shortcutMenu></shortcutMenu>
        <transition name="fade-scale">
          <search class="avue-view" v-show="isSearch"></search>
        </transition>
        <!-- 主体视图层 -->
        <div
          style="height: calc(100vh - 100px); overflow-y: auto; overflow-x: hidden;padding:10px;background:#f4f5f6;box-sizing: border-box;"
          id="avue-view"
          v-show="!isSearch"
        >
          <keep-alive>
            <router-view v-if="$route.meta.keepAlive"/>
          </keep-alive>
          <router-view v-if="!$route.meta.keepAlive"/>
        </div>
      </div>
    </div>
    <div class="avue-shade" @click="showCollapse"></div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import tags from "./tags";
import search from "./search";
import top from "./top/";
import sidebar from "./sidebar/";
import admin from "@/util/admin";
import {validatenull} from "@/util/validate";
import {calcDate} from "@/util/date.js";
import {getStore} from "@/util/store.js";
import Hamburger from './hamburger'
import shortcutMenu from "@/page/index/shortcutMenu";
export default {
  components: {
    top,
    tags,
    search,
    sidebar,
    Hamburger,
    shortcutMenu
  },
  name: "index",
  provide() {
    return {
      index: this
    };
  },
  data() {
    return {
      //搜索控制
      isSearch: false,
      //刷新token锁
      refreshLock: false,
      //刷新token的时间
      refreshTime: "",
      clientHeight: "",
      isShow:true,
      paddingLeft:"220px",
    };
  },
  watch: {
    clientHeight: {
      handler(newVal, oldVal) {
        this.initEyesClient();
      }
    }
  },
  created() {
    //实时检测刷新token
    this.refreshToken();


  },
  mounted() {
    this.init();
    this.clientHeight = document.documentElement.clientHeight;
    this.initEyesClient();
    if(this.$route.query.isHead){
      this.isShow = false
      this.paddingLeft = "0px"
    }
  },
  computed: mapGetters(["isMenu", "isLock", "isCollapse", "website", "menu","keyCollapse"]),
  props: [],
  methods: {

    showCollapse() {
      this.$store.commit("SET_COLLAPSE");
    },
    // 初始化
    init() {
      this.$store.commit("SET_SCREEN", admin.getScreen());
      window.onresize = () => {
        setTimeout(() => {
          this.$store.commit("SET_SCREEN", admin.getScreen());
        }, 0);
      };
      this.$store.dispatch("FlowRoutes").then(() => {
      });
    },
    //打开菜单
    openMenu(item = {}, clickMenu = false) {
      this.$store.dispatch("GetMenu", item.id).then(data => {
        if (data.length !== 0) {
          this.$router.$avueRouter.formatRoutes(data, true);
        }
        if (clickMenu) {
          //当点击顶部菜单后默认打开第一个菜单
          if (!this.validatenull(item)) {
            let itemActive = {},
              childItemActive = 0;
            if (item.path) {
              itemActive = item;
            } else {
              let activeMen = this.menu[childItemActive];
              if (activeMen.children) {
                while (true) {
                  if (activeMen.children.length === 0) {
                    itemActive = activeMen;
                    break
                  }
                  activeMen = activeMen.children[childItemActive];
                }
              }
            }
            this.$store.commit('SET_MENU_ID', item);
            this.$router.push({
              path: this.$router.$avueRouter.getPath({
                name: (itemActive.label || itemActive.name),
                src: itemActive.path
              }, itemActive.meta)
            });
          }
        }

      });
    },
    // 定时检测token
    refreshToken() {
      this.refreshTime = setInterval(() => {
        const token = getStore({
          name: "token",
          debug: true
        }) || {};
        const date = calcDate(token.datetime, new Date().getTime());
        if (validatenull(date)) return;
        if (date.seconds >= this.website.tokenTime && !this.refreshLock) {
          this.refreshLock = true;
          this.$store
            .dispatch("refreshToken")
            .then(() => {
              this.refreshLock = false;
            })
            .catch(() => {
              this.refreshLock = false;
            });
        }
      }, 10000);
    },

    initEyesClient() {
      this.$refs.avueHeader.style.marginLeft='0px';
      this.$refs.avueHeader.style.paddingLeft='0px';
    },
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    }
  }
};
</script>
<style lang="scss" scoped>
  .avue-left{
    padding-bottom: 40px;
    box-sizing: border-box;
  }
  .hamburger-container{
    position: absolute;
    width: 100%;
    bottom:5px;
    box-sizing: border-box;
    text-align: right;
  }
  .avue--collapse {
    .hamburger-container{
      text-align: center;
    }
  }
</style>
