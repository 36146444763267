var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isDialog
        ? _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.visible,
                "append-to-body": "",
                "destroy-on-close": "",
                title: "流程图展示",
                width: "70%",
                "custom-class": "flow-design-dialog",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.visible = $event
                },
              },
            },
            [
              _c("wf-design-base", {
                ref: "bpmn",
                staticStyle: { height: "60vh" },
                attrs: { options: _vm.option },
              }),
            ],
            1
          )
        : _c(
            "div",
            [
              _vm.visible
                ? _c("wf-design-base", {
                    ref: "bpmn",
                    staticStyle: { height: "60vh" },
                    attrs: { options: _vm.option },
                  })
                : _vm._e(),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }