var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("dv-scroll-ranking-board", {
    style: { height: _vm.kHOne + "px" },
    attrs: { config: _vm.config },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }