var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", [
      _c(
        "div",
        {
          staticClass: "colorGrass font-bold",
          style: { "font-size": _vm.kFOne + "px" },
        },
        [_vm._v("🌈近30日异常次数排行榜")]
      ),
    ]),
    _c(
      "div",
      [
        _c("dv-capsule-chart", {
          style: { width: _vm.kWOne + "px", height: _vm.kHOne + "px" },
          attrs: { config: _vm.config },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }